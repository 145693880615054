import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  error403: {
    heading: 'Hmm... Access denied!',
    description: "It looks like you don't have permission to access this page.",
    home: 'Home',
  },
};

export default enUS;
