import { LoadingDots, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const LoaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
});

const Loader = ({ label = '' }: { label?: string }) => {
  return (
    <LoaderWrapper>
      <LoadingDots size="xlarge" dataTestId="loader" />
      {label && <Paragraph>{label}</Paragraph>}
    </LoaderWrapper>
  );
};

export default Loader;
