import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Error403 as Error403Component } from '@hexa-ui/components';

import { Error403Container } from './Error403Page.styles';

const Error403Page = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Error403Container>
      <Error403Component
        header={formatMessage({ id: 'error403.heading' })}
        description={formatMessage({ id: 'error403.description' })}
        buttonText={formatMessage({ id: 'error403.home' })}
        onButtonClick={() => navigate('/release-management')}
      />
    </Error403Container>
  );
};

export default Error403Page;
