import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import { useIsAdmin, useIsApprover } from './hooks/permissions';
import Error403Page from './pages/Error403Page/Error403Page';

export const BASENAME = 'release-management';
export const ROUTES = {
  HOME: {
    Component: lazy(() => import('./pages/Bookings')),
    path: `/${BASENAME}`,
    allowedRoles: undefined,
  },
  REVIEW: {
    Component: lazy(() => import('./pages/Review')),
    path: `/${BASENAME}/review`,
    allowedRoles: ['ADMIN', 'APPROVER'],
  },
  CONFIGURATION: {
    Component: lazy(() => import('./pages/Configuration')),
    path: `/${BASENAME}/configuration`,
    allowedRoles: ['ADMIN'],
  },
  APPROVERS: {
    Component: lazy(() => import('./pages/Approvers')),
    path: `/${BASENAME}/approvers`,
    allowedRoles: ['ADMIN'],
  },
  BRIDGE_LINE: {
    Component: lazy(() => import('./pages/BridgeLine')),
    path: `/${BASENAME}/bridge-line`,
    allowedRoles: ['ADMIN', 'APPROVER'],
  },
};

const Router = (): JSX.Element => {
  const isAdmin = useIsAdmin();
  const isApprover = useIsApprover();

  const restrictedForScopes = (page: JSX.Element, allowedScopes: string[]) => {
    const returnPage = <Suspense fallback={<Loader />}>{page}</Suspense>;

    if (!allowedScopes?.length) return returnPage;
    if (allowedScopes.includes('ADMIN') && isAdmin) return returnPage;
    if (allowedScopes.includes('APPROVER') && isApprover) return returnPage;

    return <Error403Page />;
  };

  return (
    <BrowserRouter>
      <Routes>
        {Object.values(ROUTES).map(({ Component, path, allowedRoles }) => (
          <Route
            key={path}
            path={path}
            element={restrictedForScopes(<Component />, allowedRoles)}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
