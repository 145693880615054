import { styled } from '@hexa-ui/theme';

export const Error403Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 3.5rem)',
});
